<div *transloco="let t; read: 'bpOrganisation'" class="dropListColumn">
    <h5>{{ listTitle }}</h5>

    <div
        cdkDropList
        cdkDropListSortingDisabled
        #list="cdkDropList"
        [id]="listId"
        [cdkDropListData]="listData()"
        [cdkDropListConnectedTo]="connectedLists"
        class="list"
        (cdkDropListExited)="exited($event)"
        (cdkDropListDropped)="onDrop($event)">
        <form class="px-2 py-2 border-b" [formGroup]="filterForm">
            <formly-form
                [form]="filterForm"
                [fields]="filterFields"
                [model]="filterModel"></formly-form>
        </form>

        @for (item of paginatedList; track item) {
            <div
                class="dragBox"
                [ngClass]="!inDevelopmentStatus() ? 'bg-slate-100 opacity-75 cursor-default' : ''"
                cdkDrag
                [cdkDragDisabled]="!inDevelopmentStatus()">
                <img
                    *ngIf="item.metadata?.picture"
                    class="w-10 h-10 rounded-full"
                    src="{{ item.metadata?.picture }}"
                    alt="" />
                <div
                    class="w-10 h-10 flex items-center justify-center text-xs font-semibold leading-none text-center uppercase"
                    *ngIf="!item.metadata?.picture">
                    {{ t('noThumb') }}
                </div>
                <span class="my-auto">
                    {{ item.title }}
                </span>
            </div>
        }
        @if (listData() && listData().length === 0) {
            <fuse-alert
                [appearance]="'soft'"
                [type]="'basic'"
                [showIcon]="false"
                class="text-center block my-auto w-full">
                {{ t('emptyEmployeeList') }}
            </fuse-alert>
        }

        <mat-paginator
            class="demo-paginator"
            [length]="listData().length"
            [pageSize]="pageSize()"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex()"
            [hidePageSize]="true"
            (page)="handlePageEvent($event)">
        </mat-paginator>
    </div>
</div>
