<div *transloco="let t; read: 'bpOrganisation'">
    @if (!selectedItem()) {
        <fuse-alert
            [appearance]="'soft'"
            [type]="'basic'"
            [showIcon]="false"
            class="text-center block my-auto p-2 w-full">
            {{ t('selectitem') }}
        </fuse-alert>
    } @else {
        @if (fields()) {
            <form class="p-2" [formGroup]="form" (ngSubmit)="submit()">
                <formly-form [model]="model()" [fields]="fields()" [form]="form"></formly-form>
            </form>
        }
    }
</div>
