<div *transloco="let t; read: 'bpOrganisation'">
    @if (!selectedItem()) {
        <fuse-alert
            [appearance]="'soft'"
            [type]="'basic'"
            [showIcon]="false"
            class="text-center block my-auto p-2 w-full">
            {{ t('selectitem') }}
        </fuse-alert>
    } @else {
        <div class="dropListGroup">
            <app-draggable-list
                class="flex-1"
                [listId]="'todoList'"
                [listTitle]="'Employees'"
                [listData]="todo()"
                [connectedLists]="['doneList']"
                [pageSizeOptions]="pageSizeOptions"
                [filterModel]="filterModel"
                [inDevelopmentStatus]="inDevelopmentStatus()"
                (dropEvent)="drop($event)"
                (exitEvent)="exited($event)">
            </app-draggable-list>

            <app-draggable-list
                class="flex-1"
                [listId]="'doneList'"
                [listTitle]="model().title"
                [listData]="done"
                [connectedLists]="['todoList']"
                [pageSizeOptions]="pageSizeOptions"
                [filterModel]="filterModel"
                [inDevelopmentStatus]="inDevelopmentStatus()"
                (dropEvent)="drop($event)"
                (exitEvent)="exited($event)">
            </app-draggable-list>
        </div>
    }
</div>
