<div class="p-2 flex flex-row gap-4">
    <img
        class="w-24 h-24 rounded-full"
        src="assets/user_pictures/{{ componentModel.metadata }}.jpeg" />
    <div class="flex flex-col gap-2">
        <h5> {{ componentModel.first }} {{ componentModel.last }} </h5>
        <p class="flex flex-row gap-2 my-auto">
            <mat-icon svgIcon="feather:at-sign"></mat-icon> {{ componentModel.email }}
        </p>
        <p class="flex flex-row gap-2 my-auto">
            <mat-icon svgIcon="feather:phone"></mat-icon> {{ componentModel.phone }}
        </p>
    </div>
</div>
