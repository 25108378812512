import { CommonModule } from '@angular/common';
import {
    Component,
    DestroyRef,
    Injector,
    Signal,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import {
    Area,
    BpmAreasService,
    BpmSystemsService,
    Employee,
    OrganisationStructure,
    OrganisationStructureControllerService,
    System,
} from 'app/api';
import { ActionBarComponent } from 'app/core/dialogBuilder/action-bar/action-bar.component';
import { ActionDescriptor } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { HasAnyRoleDirective } from 'app/core/role-management/has-role.directive';
import { SaveButtonDirective } from 'app/core/save-button/save-button.directive';
import {
    ContentLayoutWrapperComponent,
    ContentLayoutWrapperConfig,
} from 'app/core/ui/content-layout-wrapper/content-layout-wrapper.component';
import { EmployeeService } from 'app/modules/employees/employee.service';
import { ConfirmationService } from 'app/services/confirmation/confirmation.service';
import {
    FlowchartHeaderConfig,
    FlowchartModule,
    OrganisationContainerConfig,
} from '../../../../../projects/flowchart/src/public-api';
import { EmployeeDetailsDialogComponent } from '../employee-details-dialog/employee-details-dialog.component';
import { OrgSysAreaTreeService } from '../lib-org-sys-area-tree/lib-org-sys-area.service';
import {
    AreaEditorComponent,
    OrgEditor2Component,
    OrgEditorComponent,
    SysEditorComponent,
} from '../org-sys-area-editor/org-sys-area-editor.component';
import { OrgSysAreaTreeComponent } from '../org-sys-area-tree/org-sys-area-tree.component';
import { OrgSysAreaWorkflowService } from '../org-sys-area-workflow.service';
import { OrgSysAreaFormService } from '../org-sys-area.form.service';
import { OrgSysAreaService } from '../org-sys-area.service';
import { AreaService } from '../services/area.service';
import { OrgService } from '../services/org.service';
import { SysService } from '../services/sys.service';

@Component({
    standalone: true,
    template: '',
})
export abstract class OrgSysAreaWrapperComponent<T, S> {
    currentComponent: string;

    /** The selected item from the tree */
    selectedItem = computed<T>(this.service.selectedItem);

    /** Defines the status of edit mode */
    isEditable: Signal<boolean>;

    /** The signal used to execute actions */
    executeActionSignal = signal(null);

    /** Date of the last action's execution */
    lastActionExecutedAt: Date | undefined = undefined;

    /** Content layout wrapper configuration */
    config: ContentLayoutWrapperConfig;

    /** Configuration of the Chart */
    orgChartComponentConfig: OrganisationContainerConfig;

    /** Configuration of the Header of the Chart */
    flowchartHeaderConfig: WritableSignal<FlowchartHeaderConfig> = signal(null);

    /** The current translations */
    _translocoContent = signal({});

    /** Actions that are shown in the header */
    actions: ActionDescriptor[] = [];

    activeKeys = computed(this.service.activeKeys);

    chartType: 'organisation' | 'area' | 'system' = 'organisation';

    isActivable: boolean = true;
    canBeSaved: boolean = true;
    inDevelopmentStatus: boolean = true;

    protected formService: OrgSysAreaFormService<T>;
    protected _translocoService: TranslocoService;
    protected organisationTreeService: OrgSysAreaTreeService<T>;
    protected _destroyRef: DestroyRef;

    constructor(
        protected inject: Injector,
        public service: OrgSysAreaService<T, S>
    ) {
        // #region injecting all services
        this.formService = this.inject.get(OrgSysAreaFormService<T>);
        this._translocoService = this.inject.get(TranslocoService);
        this.organisationTreeService = this.inject.get(OrgSysAreaTreeService<T>);
        this._destroyRef = this.inject.get(DestroyRef);
        // #endregion

        // Setting common service to formService
        this.formService.service = this.service;

        // Computing isEditable flag
        this.isEditable = computed<boolean>(this.formService.editableForm);

        // Subscribing to translations
        this._translocoService
            .selectTranslation('bpOrganisation')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(content => {
                this._translocoContent.set(content);
                this.setUpActions();
            });

        // Setting up the screen
        this.setUpWrapperConfig();

        // #region Effects
        effect(() => {
            let activeOrgKeys = this.activeKeys();
            this.setUpActions();
        });

        effect(
            () => {
                const code = this.executeActionSignal();
                const now = new Date();

                if (
                    !this.lastActionExecutedAt ||
                    now.getTime() - this.lastActionExecutedAt.getTime() > 1000
                ) {
                    this.handleAction(code);
                    this.closeAction(now);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    protected activate(): void {
        this.service.activate();
    }

    /**
     * Handles the action code in the child component
     * @param code Code of the action
     */
    protected abstract handleAction(code: string): void;

    /**
     * Closes the action by setting it to null
     * @param now The date when the last action was executed
     */
    private closeAction(now: Date): void {
        this.lastActionExecutedAt = now;
        this.executeActionSignal.set(null);
    }

    /**
     * This function should set up the available actions for the header bar
     * and store them in the property called actions
     */
    protected abstract setUpActions(): void;

    /**
     * Changes the status of edit mode
     */
    changeEditStatus() {
        this.formService.editableForm = !this.formService.editableForm();
    }

    /**
     * Sets up the configuration for the WrapperBuilder
     */
    setUpWrapperConfig(): void {
        this.config = {
            rightSection: {
                header: {},
            },
            leftSection: {
                header: {
                    showToggleButton: true,
                },
                content: {},
            },
            ultimateSideBar: {
                isOpened: true,
            },
        };

        this.orgChartComponentConfig = {
            service: this.service,
            executeActionSignal: this.executeActionSignal,
        };

        this.flowchartHeaderConfig.set({
            actions: [],
            executeActionSignal: this.executeActionSignal,
            resizeFlowchart: true,
        });
    }

    // #region manual buttons
    /**
     * Deletes the selected item from the tree
     */
    deleteItem() {
        this.service.delete();
    }

    /**
     * Starts the creating workflow for a new Item
     * @param type Type of the new item
     */
    abstract newItem(type: string);

    /**
     * Starts the saving workflow
     */
    save() {
        this.formService.save();
    }

    // #endregion
}

const componentImports = [
    CommonModule,
    TranslocoModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    ContentLayoutWrapperComponent,
    FlowchartModule,
    SaveButtonDirective,
    ActionBarComponent,
    OrgSysAreaTreeComponent,
    HasAnyRoleDirective,
];

@Component({
    selector: 'org-wrapper',
    standalone: true,
    imports: [...componentImports, OrgEditorComponent],
    templateUrl: './org-sys-area-wrapper.component.html',
    styleUrl: './org-sys-area-wrapper.component.scss',
})
export class OrgWrapperComponent extends OrgSysAreaWrapperComponent<
    OrganisationStructure,
    OrganisationStructureControllerService
> {
    override currentComponent: string = 'org';

    constructor(
        protected inject: Injector,
        public service: OrgService,
        private workflowService: OrgSysAreaWorkflowService,
        private confirmDialogService: ConfirmationService
    ) {
        super(inject, service);

        effect(() => {
            const selectedItem = this.service.selectedItem();

            this.setUpActions();

            if (selectedItem) {
                let rootNode = this.organisationTreeService.findRootParentNode(
                    this.service.selectedItem().id
                );

                if (!rootNode) {
                    rootNode = selectedItem;
                }

                this.isActivable =
                    selectedItem['versionId'] && selectedItem['status'] === 'DEVELOPMENT';
                this.inDevelopmentStatus = rootNode['status'] === 'DEVELOPMENT';
                if (!this.inDevelopmentStatus) {
                    setTimeout(() => {
                        this.formService.editableForm = false;
                    });
                }
            } else {
                this.isActivable = false;
                this.inDevelopmentStatus = false;
            }
        });
    }

    protected handleAction(code: string): void {
        if (code && code === 'createNewVersion') {
            this.confirmDialogService
                .open({
                    title: 'createNewVersion',
                    message: 'createNewVersionDescription',
                    icon: {
                        color: 'primary',
                    },
                    actions: {
                        cancel: {
                            label: 'cancel',
                        },
                        confirm: {
                            label: 'createNewVersion',
                            color: 'primary',
                        },
                    },
                })
                .afterClosed()
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(response => {
                    if (response === 'confirmed') {
                        const organisations = this.service.state();
                        const activeOrg = organisations[0];
                        const newOrg = { ...activeOrg };

                        newOrg.versionId = undefined;

                        this.service.createItem(newOrg);
                    }
                });
        } else if (code && code === 'createSiblingItem') {
            setTimeout(() => {
                this.service.selectItemById(this.service.selectedItem().id);
                this.workflowService.startWorkflow('org', 'sameLevel', this.service);
            });
        } else if (code && code === 'createChildItem') {
            setTimeout(() => {
                this.workflowService.startWorkflow('org', 'asChild', this.service);
            });
        }
    }
    protected setUpActions(): void {
        let visible = true;
        let newVersionVisible = true;
        if (this.selectedItem()) {
            visible = this.organisationTreeService.checkIfContextMenuItemVisible(
                this.service.selectedItem()
            );
        }
        if (this.service.state()?.some(org => org.status === 'DEVELOPMENT')) {
            newVersionVisible = false;
        }
        this.actions = [
            {
                code: 'createNewVersion',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createNewVersion'],
                icon: 'mat_solid:computer',
                hide: !newVersionVisible,
            },
            {
                code: 'createSiblingItem',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createSiblingItem'],
                icon: 'mat_solid:computer',
                hide: !visible,
            },
            {
                code: 'createChildItem',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createChildItem'],
                icon: 'mat_solid:computer',
                hide: !visible,
            },
        ];
    }
    newItem(type: string) {
        const topLevelNode = this.organisationTreeService.treeNodes()[0];

        setTimeout(() => {
            this.service.newItem(topLevelNode as any, type as any);
        });
    }

    override deleteItem() {
        this.service.delete(undefined, 'org');
    }
}

@Component({
    selector: 'org-wrapper',
    standalone: true,
    imports: [...componentImports, OrgEditor2Component],
    templateUrl: './org-sys-area-wrapper.component.html',
    styleUrl: './org-sys-area-wrapper.component.scss',
})
export class OrgWrapper2Component extends OrgSysAreaWrapperComponent<
    OrganisationStructure,
    OrganisationStructureControllerService
> {
    override currentComponent: string = 'org2';
    override canBeSaved: boolean = false;
    override isActivable: boolean = false;

    constructor(
        protected inject: Injector,
        public service: OrgService,
        private workflowService: OrgSysAreaWorkflowService,
        private confirmDialogService: ConfirmationService,
        private dialogService: DialogBuilderService,
        private employeeService: EmployeeService
    ) {
        super(inject, service);

        effect(() => {
            const selectedItem = this.service.selectedItem();
            this.setUpActions();

            if (selectedItem) {
                let rootNode = this.organisationTreeService.findRootParentNode(selectedItem.id);

                if (!rootNode) {
                    rootNode = selectedItem;
                }

                this.inDevelopmentStatus = rootNode['status'] === 'DEVELOPMENT';
                if (!this.inDevelopmentStatus) {
                    setTimeout(() => {
                        this.formService.editableForm = false;
                    });
                }
            } else {
                this.isActivable = false;
                this.inDevelopmentStatus = false;
            }
        });
    }

    onDoubleClicked($event): void {
        const id = $event.split('_')[1];

        if (!id) {
            console.error('no id found');
            return;
        }
        this.employeeService.availableEmployees
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(employees => {
                const employee = employees.find(employee => employee.employee_id === id);
                if (!employee) {
                    console.error('employee not found');
                    return;
                }
                this.dialogService.openDialog({
                    descriptor: {
                        header: {
                            title: this._translocoContent()['employeeDetails'],
                            showCloseButton: true,
                        },
                        content: {
                            componentConfig: {
                                component: EmployeeDetailsDialogComponent,
                                componentData: {
                                    model: employee,
                                },
                            },
                        },
                        actions: {
                            dialogActions: [],
                        },
                        dialogSize: 'l',
                        executeActionSignal: this.executeActionSignal,
                    },
                });
            });
    }

    protected handleAction(code: string): void {
        if (code && code === 'createNewVersion') {
            this.confirmDialogService
                .open({
                    title: 'createNewVersion',
                    message: 'createNewVersionDescription',
                    icon: {
                        color: 'primary',
                    },
                    actions: {
                        cancel: {
                            label: 'cancel',
                        },
                        confirm: {
                            label: 'createNewVersion',
                            color: 'primary',
                        },
                    },
                })
                .afterClosed()
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(response => {
                    if (response === 'confirmed') {
                        const organisations = this.service.state();
                        const activeOrg = organisations[0];
                        const newOrg = { ...activeOrg };

                        newOrg.versionId = undefined;

                        // return;
                        this.service.createItem(newOrg);
                    }
                });
        } else if (code && code === 'createSiblingItem') {
            setTimeout(() => {
                this.service.selectItemById(this.service.selectedItem().id);
                this.workflowService.startWorkflow('org', 'sameLevel', this.service);
            });
        } else if (code && code === 'createChildItem') {
            setTimeout(() => {
                this.workflowService.startWorkflow('org', 'asChild', this.service);
            });
        }
    }
    protected setUpActions(): void {
        this.actions = [];
    }
    newItem(type: string) {
        const topLevelNode = this.organisationTreeService.treeNodes()[0];

        setTimeout(() => {
            this.service.newItem(topLevelNode as any, type as any);
        });
    }

    override save() {
        this.service.updateItem(this.service.selectedItem());
    }

    override deleteItem() {
        this.service.delete(undefined, 'org');
    }

    setRolesForEmployeesByOrganisation(
        organisation: OrganisationStructure,
        employees: Employee[]
    ): Employee[] {
        if (organisation['employees']) {
            organisation['employees'].forEach(employee => {
                const found = employees.find(x => x.employee_id === employee.id);
                if (found) {
                    if (found.roles) {
                        found.roles.push(organisation.id);
                    } else {
                        found.roles = [organisation.id];
                    }
                }
            });
        }

        if (organisation.children) {
            organisation.children.forEach(child => {
                employees = this.setRolesForEmployeesByOrganisation(child, employees);
            });
        }

        return employees;
    }
}

@Component({
    selector: 'sys-wrapper',
    standalone: true,
    imports: [...componentImports, SysEditorComponent],
    templateUrl: './org-sys-area-wrapper.component.html',
    styleUrl: './org-sys-area-wrapper.component.scss',
    providers: [OrgSysAreaWorkflowService],
})
export class SysWrapperComponent extends OrgSysAreaWrapperComponent<System, BpmSystemsService> {
    override currentComponent: string = 'sys';
    override chartType: 'organisation' | 'area' | 'system' = 'system';

    constructor(
        protected inject: Injector,
        public service: SysService,
        private workflowService: OrgSysAreaWorkflowService,
        private confirmDialogService: ConfirmationService
    ) {
        super(inject, service);

        effect(() => {
            const selectedItem = this.service.selectedItem();

            this.setUpActions();

            if (selectedItem) {
                let rootNode = this.organisationTreeService.findRootParentNode(
                    this.service.selectedItem().id
                );

                if (!rootNode) {
                    rootNode = selectedItem;
                }

                this.isActivable =
                    selectedItem['versionId'] && selectedItem['status'] === 'DEVELOPMENT';
                this.inDevelopmentStatus = rootNode['status'] === 'DEVELOPMENT';
                if (!this.inDevelopmentStatus) {
                    setTimeout(() => {
                        this.formService.editableForm = false;
                    });
                }
            } else {
                this.isActivable = false;
                this.inDevelopmentStatus = false;
            }
        });
    }

    protected handleAction(code: string): void {
        if (code && code === 'createNewVersion') {
            this.confirmDialogService
                .open({
                    title: 'createNewVersion',
                    message: 'createNewVersionDescription',
                    icon: {
                        color: 'primary',
                    },
                    actions: {
                        cancel: {
                            label: 'cancel',
                        },
                        confirm: {
                            label: 'createNewVersion',
                            color: 'primary',
                        },
                    },
                })
                .afterClosed()
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(response => {
                    if (response === 'confirmed') {
                        const activeOrg = this.service.selectedItem();

                        const newOrg = { ...activeOrg };

                        newOrg.id = newOrg.id.split('_')[1];
                        newOrg.versionId = undefined;
                        newOrg.version = `${newOrg.version} - new`;

                        this.service.createItem(newOrg);
                    }
                });
        } else if (code && code === 'createSiblingItem') {
            setTimeout(() => {
                this.workflowService.startWorkflow('sys', 'sameLevel', this.service);
            });
        } else if (code && code === 'createChildItem') {
            setTimeout(() => {
                this.workflowService.startWorkflow('sys', 'asChild', this.service);
            });
        }
    }
    protected setUpActions(): void {
        let newVersionVisible = false;
        if (this.selectedItem()) {
            let root = this.service.findRootParentNode(this.service.selectedItem().id);
            if (!root) {
                root = this.service.selectedItem();
            }

            const versions = this.service.state().filter(org => {
                return org.id === (root.id.includes('_') ? root.id.split('_')[1] : root.id);
            });

            const rootHasDevelopmentVersion = versions.some(org => org.status === 'DEVELOPMENT');

            newVersionVisible = root['status'] === 'ACTIVE' && !rootHasDevelopmentVersion;
        }

        this.actions = [
            {
                code: 'createNewVersion',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createNewVersion'],
                icon: 'mat_solid:computer',
                hide: !newVersionVisible,
            },
            {
                code: 'createSiblingItem',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createSiblingItem'],
                icon: 'mat_solid:computer',
                hide: false, // !this.service.visibilityOfDevelopmentFunctionalities(),
            },
            {
                code: 'createChildItem',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createChildItem'],
                icon: 'mat_solid:computer',
                hide: !this.service.visibilityOfDevelopmentFunctionalities(),
            },
        ];
    }
    newItem(type: string) {
        this.service.newItem();
    }
}

@Component({
    selector: 'org-sys-area-wrapper',
    standalone: true,
    imports: [...componentImports, AreaEditorComponent],
    templateUrl: './org-sys-area-wrapper.component.html',
    styleUrl: './org-sys-area-wrapper.component.scss',
    providers: [OrgSysAreaWorkflowService],
})
export class AreaWrapperComponent extends OrgSysAreaWrapperComponent<Area, BpmAreasService> {
    override currentComponent: string = 'area';
    override chartType: 'organisation' | 'area' | 'system' = 'area';

    constructor(
        protected inject: Injector,
        public service: AreaService,
        private workflowService: OrgSysAreaWorkflowService,
        private confirmDialogService: ConfirmationService
    ) {
        super(inject, service);

        effect(() => {
            const selectedItem = this.service.selectedItem();

            this.setUpActions();

            if (selectedItem) {
                let rootNode = this.organisationTreeService.findRootParentNode(
                    this.service.selectedItem().id
                );

                if (!rootNode) {
                    rootNode = selectedItem;
                }

                this.isActivable =
                    selectedItem['versionId'] && selectedItem['status'] === 'DEVELOPMENT';
                this.inDevelopmentStatus = rootNode['status'] === 'DEVELOPMENT';
                if (!this.inDevelopmentStatus) {
                    setTimeout(() => {
                        this.formService.editableForm = false;
                    });
                }
            } else {
                this.isActivable = false;
                this.inDevelopmentStatus = false;
            }
        });
    }

    protected handleAction(code: string): void {
        if (code && code === 'createNewVersion') {
            this.confirmDialogService
                .open({
                    title: 'createNewVersion',
                    message: 'createNewVersionDescription',
                    icon: {
                        color: 'primary',
                    },
                    actions: {
                        cancel: {
                            label: 'cancel',
                        },
                        confirm: {
                            label: 'createNewVersion',
                            color: 'primary',
                        },
                    },
                })
                .afterClosed()
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(response => {
                    if (response === 'confirmed') {
                        const activeOrg = this.service.selectedItem();
                        const newOrg = { ...activeOrg };

                        newOrg.id = newOrg.id.split('_')[1];
                        newOrg.versionId = undefined;
                        newOrg.version = `${newOrg.version} - new`;

                        this.service.createItem(newOrg);
                    }
                });
        } else if (code && code === 'createSiblingItem') {
            setTimeout(() => {
                this.workflowService.startWorkflow('area', 'sameLevel', this.service);
            });
        } else if (code && code === 'createChildItem') {
            setTimeout(() => {
                this.workflowService.startWorkflow('area', 'asChild', this.service);
            });
        }
    }
    protected setUpActions(): void {
        let newVersionVisible = false;
        let visible = false;
        if (this.selectedItem()) {
            visible = this.organisationTreeService.checkIfContextMenuItemVisible(
                this.service.selectedItem()
            );
            let root = this.service.findRootParentNode(this.service.selectedItem().id);
            if (!root) {
                root = this.service.selectedItem();
            }

            const versions = this.service.state().filter(org => {
                return org.id === (root.id.includes('_') ? root.id.split('_')[1] : root.id);
            });

            const rootHasDevelopmentVersion = versions.some(org => org.status === 'DEVELOPMENT');

            newVersionVisible = root['status'] === 'ACTIVE' && !rootHasDevelopmentVersion;
        }

        this.actions = [
            {
                code: 'createNewVersion',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createNewVersion'],
                icon: 'mat_solid:computer',
                hide: !newVersionVisible,
            },
            {
                code: 'createSiblingItem',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createSiblingItem'],
                icon: 'mat_solid:computer',
                hide: false, //!this.service.visibilityOfDevelopmentFunctionalities() || !visible,
            },
            {
                code: 'createChildItem',
                style: 'icon-button',
                tooltip: this._translocoContent()['workflow.createChildItem'],
                icon: 'mat_solid:computer',
                hide: !this.service.visibilityOfDevelopmentFunctionalities(),
            },
        ];
    }
    newItem(type: string) {
        this.service.newItem();
    }
}
